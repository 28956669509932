import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  enUS,
  frFR,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { Flex } from 'src/components/basic/Flex';
import ProfileCard from 'src/components/basic/ProfileCard';
import { CustomLoadingOverlay } from 'src/components/dataGrid/CustomLoadingOverlay';
import { CustomNoRowsOverlay } from 'src/components/dataGrid/CustomNoRowsOverlay';
import { theme } from 'src/constants/theme';
import { handleNotification } from 'src/helpers/notifications';
import { useAppDispatch } from 'src/hooks/store';
import {
  activateAccount,
  deactivateAccount,
  deleteAccount,
  getUser,
  updateUserCompanyRole,
  updateUserRole,
} from 'src/slices/auth';
import { User, UserJobType } from 'src/types/auth.type';
import { Organization } from 'src/types/organization.type';

type GridRowType = Omit<User, 'profilePictureUrl'> & {
  id: string;
  avatar?: string;
  company: string;
  numberOfConnections: number;
  isActiveAccount: boolean;
  jobType?: UserJobType;
};
interface Props {
  users: User[];
  organizations: Organization[];
}
export const AdminUsers: React.FC<Props> = ({ users, organizations }) => {
  const dispatch = useAppDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t, i18n } = useTranslation();

  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);
  const [clickedUserId, setClickedUserId] = useState<string | null>(null);

  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);

  const [deleteUserDialog, setDeleteUserDialog] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const selectedUsers = useMemo(
    () =>
      selectedUsersIds
        .map((id) => users.find((user) => user._id === id))
        .filter((user): user is User => !!user),
    [selectedUsersIds, users],
  );

  const clickedUser = useMemo(
    () => users.find((user) => user._id === clickedUserId),
    [clickedUserId, users],
  );

  const columns: GridColDef<GridRowType>[] = [
    { field: 'firstName', headerName: t('common.firstName'), minWidth: 150 },
    { field: 'lastName', headerName: t('common.lastName'), minWidth: 150 },
    { field: 'email', headerName: t('common.email'), minWidth: 200 },
    { field: 'company', headerName: t('common.company'), minWidth: 150 },
    {
      field: 'jobType',
      headerName: t('user.jobType.label'),
      renderCell: (cellValues) =>
        cellValues.row.jobType
          ? t(`user.jobType.options.${cellValues.row.jobType}`)
          : '',
      minWidth: 150,
    },
    {
      field: 'isActiveAccount',
      headerName: t('pages.Admin.activeAccount'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) =>
        cellValues.row.isActiveAccount ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckIcon
              color="success"
              style={{ marginLeft: theme.spacing(0.5) }}
            />
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CloseIcon
              color="error"
              style={{ marginLeft: theme.spacing(0.5) }}
            />
          </div>
        ),
      maxWidth: 100,
    },
    {
      field: 'totalVictimes',
      headerName: t('pages.Admin.totalVictimes'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => (
        <Typography>{cellValues.row.victimes?.length || 0}</Typography>
      ),
    },
    {
      field: 'isAdmin',
      headerName: t('pages.Admin.isAdmin'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) =>
        cellValues.row.isAdmin ? (
          <AdminPanelSettingsIcon color="primary" />
        ) : (
          <div>{t('pages.Admin.noAdmin')}</div>
        ),
      minWidth: 150,
    },
  ];

  const rows: GridRowType[] = users.map((user) => ({
    ...user,
    id: user._id,
    company: user?.company ?? '',
    lastConnection: user.stats?.lastConnection ?? user.createdAt,
    numberOfConnections: user.stats?.numberOfConnections ?? 0,
    isAdmin: user.isAdmin,
    isActiveAccount: !user.disabled,
    jobType: user.jobType,
  }));

  const adminDeleteAccount = (userId: string) => {
    setDeleteUserDialog(userId);
    setOpenDeleteDialog(true);
  };

  const dialogAdminDeleteAccount = async () => {
    if (deleteUserDialog !== '') {
      await dispatch(deleteAccount(deleteUserDialog));
      setOpenDeleteDialog(false);
      setDeleteUserDialog('');
    }
  };

  const updateRole = (userId: string, isAdmin: boolean) => {
    dispatch(updateUserRole({ userId, isAdmin }));
  };

  const adminActivateAccount = (userId: string) => {
    dispatch(activateAccount(userId));
  };

  const adminDeactivateAccount = (userId: string) => {
    dispatch(deactivateAccount(userId));
  };

  const adminGetUser = async (userId: string): Promise<User> => {
    const resultAction = await dispatch(getUser(userId));

    if (getUser.fulfilled.match(resultAction)) {
      return resultAction.payload as User;
    } else {
      throw resultAction.error;
    }
  };

  const updateCompanyRole = async (
    userId: string,
    dto: { company: string; role?: string; jobType?: UserJobType },
  ) => {
    if (dto.company) {
      await handleNotification(
        dispatch(
          updateUserCompanyRole({
            userId,
            ...dto,
          }),
        ),
        t('pages.Admin.successUpdateUser'),
        t('pages.Admin.errorUpdateUser'),
        enqueueSnackbar,
        closeSnackbar,
      );
    }
  };

  const onClickRow = (params: GridRowParams<GridRowType>) => {
    setDialogOpened(true);
    setClickedUserId(params.row._id);
  };

  const displayProfileCard = (user: User) => (
    <ProfileCard
      user={user}
      updateRole={updateRole}
      adminDeleteAccount={adminDeleteAccount}
      updateCompanyRole={updateCompanyRole}
      activateAccount={adminActivateAccount}
      deactivateAccount={adminDeactivateAccount}
      getUser={adminGetUser}
      organizations={organizations}
    />
  );
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DataGrid
        sx={{
          cursor: 'default',
          boxShadow:
            'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, \
           &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },
        }}
        localeText={
          i18n.language === 'en'
            ? enUS.components.MuiDataGrid.defaultProps.localeText
            : frFR.components.MuiDataGrid.defaultProps.localeText
        }
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: () => (
            <CustomNoRowsOverlay text={t('pages.Admin.noUsers')} />
          ),
          NoResultsOverlay: CustomNoRowsOverlay,
        }}
        loading={false}
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onRowClick={onClickRow}
        onRowSelectionModelChange={(gridUsers) =>
          setSelectedUsersIds(gridUsers.map((gridUser) => gridUser.toString()))
        }
        pageSizeOptions={[10, 20, 50]}
      />
      <Flex
        sx={{
          marginTop: 3,
          marginBottom: 1,
          justifyContent: 'flex-end',
          marginX: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setDialogOpened(true);
          }}
          disabled={selectedUsers?.length === 0}
        >
          {t('pages.Admin.showUsers')}
        </Button>
      </Flex>
      <Dialog
        open={Boolean(
          isDialogOpened && (selectedUsers?.length !== 0 || clickedUser),
        )}
        onClose={() => {
          setDialogOpened(false);
          setSelectedUsersIds([]);
          setClickedUserId(null);
        }}
      >
        {clickedUser ? (
          displayProfileCard(clickedUser)
        ) : selectedUsers?.length === 1 && selectedUsers?.[0] ? (
          displayProfileCard(selectedUsers[0])
        ) : selectedUsers?.length > 1 ? (
          <DialogContent>
            <Grid container spacing={2}>
              {selectedUsers.map((user) => {
                return (
                  <Grid item xs={12} key={user._id}>
                    {displayProfileCard(user)}
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
        ) : null}
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setDeleteUserDialog('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('pages.Admin.confirmDeleteUserTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('pages.Admin.confirmDeleteUser')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              setDeleteUserDialog('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogAdminDeleteAccount()} autoFocus>
            {t('common.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
