import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TooltipTableHeader } from 'src/components/client/TooltipTableHeader';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { HiddenFieldForm } from 'src/components/forms/HiddenFieldForm';
import { ReadFieldForm } from 'src/components/forms/ReadFieldForm';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { LayoutTable, NormalTable, SpanNoWrap } from 'src/components/styled';
import { CalculsGlobal } from 'src/constants/calculs';
import { CalculPerteRevenusProche } from 'src/constants/calculs/calculPerteRevenusProche';
import { getAgesLastArrerage } from 'src/helpers/bareme/bareme';
import { fCurrency } from 'src/helpers/formatNumber';
import { fYears } from 'src/helpers/formatTime';
import { getCapitalisationCoefficient } from 'src/helpers/prejudices/capitalisation';
import { Bareme, BaremeCapitalisationValue } from 'src/types/bareme.type';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { PartialRecapitulatif } from '../PartialRecapitulatif';

interface Props {
  control: Control<PrejudiceFormPerteRevenusProche>;
  procedure: Procedure;
  victime: Victime;
  dateLiquidation: Date | undefined;
  baremes: Bareme[];
  capitalisationBaremes: Bareme[];
  victimesIndirectes: VictimeIndirecte[];
}

export const PerteEnfants: React.FC<Props> = ({
  control,
  procedure,
  victime,
  dateLiquidation,
  baremes,
  capitalisationBaremes,
  victimesIndirectes,
}) => {
  const { t } = useTranslation();
  const victimesIndirectesEnfants = useMemo(
    () =>
      victimesIndirectes.filter((victime) => victime.lienVictime === 'enfant'),
    [victimesIndirectes],
  );
  const { fields } = useFieldArray({ name: 'enfants.rows', control });

  return (
    <Stack
      spacing={2}
      sx={{ alignSelf: 'start', marginTop: 2 }}
      alignItems="start"
    >
      <Stack
        sx={{ maxWidth: '700px', marginBottom: 2 }}
        spacing={2}
        direction="row"
      >
        <Typography>
          {t(
            'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.perteAnnuelle.tableRowHeader',
          )}
        </Typography>
        <ReadFieldForm
          control={control}
          name="foyer.perteAnnuelle"
          render={(value) => <SpanNoWrap>{fCurrency(value)}</SpanNoWrap>}
        />
      </Stack>
      <TextFieldForm
        control={control}
        name="enfants.partTotale"
        label={t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.partTotale.label',
        )}
        InputProps={{
          inputComponent: MaskNumber as any,
          inputProps: {
            numberMask: { scale: 0 },
            suffix: '%',
          } as IMaskNumberProps,
          sx: {
            height: '40px',
          },
        }}
        InputLabelProps={{ shrink: true }}
      />
      <TableContainer component={Paper}>
        <NormalTable>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.nom.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.ageFinSoutienFinancier.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.partIndividuelle.columnHeader',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              const victimeIndirecte = victimesIndirectes.find(
                (victimeIndirecte) =>
                  victimeIndirecte._id === field.victimeIndirecteId,
              );
              if (victimeIndirecte) {
                return (
                  <TableRow key={field.id}>
                    <TableCell align="left">
                      {`${victimeIndirecte.prenom} ${victimeIndirecte.nom}`}
                    </TableCell>
                    <TableCell align="center">
                      <TextFieldForm
                        control={control}
                        name={`enfants.rows.${index}.ageFinSoutienFinancier`}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 0 },
                            suffix: '\\ans',
                          } as IMaskNumberProps,
                        }}
                        fullWidth
                        sx={{ width: 268 }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <ComputedTextFieldForm
                        control={control}
                        watchFields={['enfants.partTotale']}
                        name={`enfants.rows.${index}.partIndividuelle`}
                        compute={([partTotale]) =>
                          CalculPerteRevenusProche.getPartIndividuelleEnfant({
                            partTotale,
                            victimesIndirectesEnfantsLength:
                              victimesIndirectesEnfants.length,
                          })
                        }
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 2 },
                            suffix: '%',
                          } as IMaskNumberProps,
                        }}
                        fullWidth
                        editedFieldsName="editedFields"
                      />
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <Typography variant="h6" marginTop={4}>
        {t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.entreDecesEtLiquidation.title',
        )}
      </Typography>
      <TableContainer>
        <NormalTable>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.nom.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.partPerteAnnuelle.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                <TooltipTableHeader
                  tooltipTitle={t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.dureeIndemnisation.headerTooltip',
                  )}
                  headerLabel={t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.dureeIndemnisation.columnHeader',
                  )}
                  justifyContent="center"
                />
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.prejudiceEntreDecesEtLiquidation.columnHeader',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              const victimeIndirecte = victimesIndirectes.find(
                (victimeIndirecte) =>
                  victimeIndirecte._id === field.victimeIndirecteId,
              );
              if (victimeIndirecte) {
                return (
                  <TableRow key={field.id}>
                    <TableCell align="left">
                      {`${victimeIndirecte.prenom} ${victimeIndirecte.nom}`}
                    </TableCell>
                    <TableCell align="center">
                      <ComputedReadFieldForm
                        control={control}
                        name={`enfants.rows.${index}.partPerteAnnuelle`}
                        watchFields={[
                          `enfants.rows.${index}.partIndividuelle`,
                          `foyer.perteAnnuelle`,
                        ]}
                        compute={([
                          partIndividuelleEnfant,
                          perteAnnuelleFoyer,
                        ]) =>
                          CalculPerteRevenusProche.getPartPerteAnnuelleEnfant({
                            partIndividuelleEnfant: partIndividuelleEnfant ?? 0,
                            perteAnnuelleFoyer,
                          })
                        }
                        fullWidth
                        render={(value) => <>{fCurrency(value || 0)}</>}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <ComputedReadFieldForm
                        control={control}
                        name={`enfants.rows.${index}.dureeIndemnisation`}
                        watchFields={[
                          `enfants.rows.${index}.ageFinSoutienFinancier`,
                        ]}
                        compute={([ageFinSoutienFinancier]) =>
                          CalculPerteRevenusProche.getDureeIndemnisationEnfant({
                            dateNaissanceEnfant: new Date(
                              victimeIndirecte.dateNaissance,
                            ),
                            dateLiquidation,
                            dateDeces: victime.dateDeces
                              ? new Date(victime.dateDeces)
                              : undefined,
                            ageFinSoutienFinancier:
                              ageFinSoutienFinancier ?? undefined,
                          })
                        }
                        fullWidth
                        render={(value) => <>{fYears(value || 0)}</>}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <ComputedReadFieldForm
                        control={control}
                        name={`enfants.rows.${index}.prejudiceEntreDecesEtLiquidation`}
                        watchFields={[
                          `enfants.rows.${index}.partPerteAnnuelle`,
                          `enfants.rows.${index}.dureeIndemnisation`,
                        ]}
                        compute={([partPerteAnnuelle, dureeIndemnisation]) =>
                          CalculPerteRevenusProche.getPrejudiceEntreDecesEtLiquidationEnfant(
                            {
                              partPerteAnnuelleEnfant: partPerteAnnuelle,
                              dureeIndemnisationEnfant: dureeIndemnisation,
                            },
                          )
                        }
                        fullWidth
                        render={(value) => <>{fCurrency(value || 0)}</>}
                      />
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <Typography variant="h6" marginTop={4}>
        {t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.apresLiquidation.title',
        )}
      </Typography>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.nom.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.ageLiquidation.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.partPerteAnnuelle.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.capitalisation.form.fields.ageDernierArrerage.title',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.capitalisation.form.fields.coefficient.otherTitle',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.total.columnHeader',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              const victimeIndirecte = victimesIndirectes.find(
                (victimeIndirecte) =>
                  victimeIndirecte._id === field.victimeIndirecteId,
              );
              if (victimeIndirecte) {
                const ageLiquidation = CalculsGlobal.getAgeOrZero(
                  victimeIndirecte.dateNaissance,
                  dateLiquidation,
                );
                return (
                  <ComputedPropsForm
                    control={control}
                    key={field.id}
                    watchFields={[
                      `enfants.rows.${index}.ageFinSoutienFinancier`,
                    ]}
                    compute={([ageFinSoutienFinancier]) => {
                      if (!dateLiquidation) {
                        return {};
                      }

                      return {
                        hidden:
                          CalculPerteRevenusProche.isFinSoutienFinancierBeforeLiquidation(
                            {
                              dateNaissance: victimeIndirecte.dateNaissance,
                              dateLiquidation,
                              ageFinSoutienFinancier:
                                ageFinSoutienFinancier ?? undefined,
                            },
                          ),
                      };
                    }}
                    render={() => (
                      <TableRow>
                        <TableCell>{`${victimeIndirecte.prenom} ${victimeIndirecte.nom}`}</TableCell>
                        <TableCell align="center">
                          {ageLiquidation.age !== undefined
                            ? t('forms.fields.age.value', {
                                count: ageLiquidation.age,
                                context: ageLiquidation.isExactlyZero
                                  ? 'exactly_zero'
                                  : undefined,
                              })
                            : ''}
                        </TableCell>
                        <TableCell align="center">
                          <ReadFieldForm
                            control={control}
                            name={`enfants.rows.${index}.partPerteAnnuelle`}
                            render={(value) => <>{fCurrency(value || 0)}</>}
                          />
                        </TableCell>
                        <ComputedPropsForm
                          control={control}
                          watchFields={['baremeCapitalisation']}
                          compute={([baremeCapitalisation]) => {
                            const selectedBareme = capitalisationBaremes.find(
                              (bareme) => bareme._id === baremeCapitalisation,
                            );
                            const enfantAgeDateAttribution =
                              CalculsGlobal.getAge(
                                victimeIndirecte.dateNaissance,
                                dateLiquidation || new Date(),
                              );
                            const agesLastArrerage =
                              enfantAgeDateAttribution !== undefined
                                ? getAgesLastArrerage(
                                    (selectedBareme?.values ||
                                      []) as BaremeCapitalisationValue[],
                                    victime.sexe,
                                    enfantAgeDateAttribution,
                                  )
                                : undefined;
                            return {
                              props: {
                                agesLastArrerage: sortBy(
                                  (agesLastArrerage?.ages || []).filter(
                                    (age) => age <= 45,
                                  ),
                                ),
                                isTextField: selectedBareme?.source === 'CJ',
                              },
                            };
                          }}
                          render={({ agesLastArrerage, isTextField }) => (
                            <>
                              <TableCell align="center">
                                {isTextField ? (
                                  <TextFieldForm
                                    control={control}
                                    name={`enfants.rows.${index}.ageDernierArrerage`}
                                    InputProps={{
                                      inputComponent: MaskNumber as any,
                                      inputProps: {
                                        numberMask: { scale: 0 },
                                        suffix: '\\ans',
                                      } as IMaskNumberProps,
                                    }}
                                    fullWidth
                                    sx={{ width: 268 }}
                                  />
                                ) : (
                                  <SelectFieldForm
                                    control={control}
                                    name={`enfants.rows.${index}.ageDernierArrerage`}
                                    sx={{ width: 268 }}
                                    allowNoSelection
                                    noSelectionText={t(
                                      'prejudice.capitalisation.form.fields.ageDernierArrerage.noSelectionText',
                                    )}
                                    options={(
                                      agesLastArrerage as number[] | undefined
                                    )?.map((age) => ({
                                      label: t(
                                        'prejudice.capitalisation.form.fields.ageDernierArrerage.age',
                                        { age },
                                      ),
                                      value: age,
                                    }))}
                                  />
                                )}
                              </TableCell>
                            </>
                          )}
                        />
                        <TableCell align="center">
                          <ComputedTextFieldForm
                            control={control}
                            name={`enfants.rows.${index}.coefficientCapitalisation`}
                            watchFields={[
                              `enfants.rows.${index}.ageDernierArrerage`,
                              'baremeCapitalisation',
                            ]}
                            compute={([
                              ageDernierArrerage,
                              baremeCapitalisation,
                            ]) => {
                              const bareme: Bareme | undefined = baremes.find(
                                (bareme) => bareme._id === baremeCapitalisation,
                              );
                              if (
                                bareme &&
                                victime.dateNaissance &&
                                dateLiquidation
                              ) {
                                const enfantAgeDateAttribution =
                                  CalculsGlobal.getAge(
                                    victimeIndirecte.dateNaissance,
                                    dateLiquidation,
                                  );
                                return (
                                  getCapitalisationCoefficient({
                                    sexe: victime.sexe,
                                    baremeValues:
                                      bareme.values as BaremeCapitalisationValue[],
                                    ageDateAttribution:
                                      enfantAgeDateAttribution,
                                    ageDernierArrerage:
                                      Number(ageDernierArrerage),
                                    isDernierArrerageViager: false,
                                    dateNaissance: victime.dateNaissance,
                                    dateLiquidation,
                                  }) || 0
                                );
                              }
                              return 0;
                            }}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 3 },
                                nullable: true,
                              } as IMaskNumberProps,
                            }}
                            fullWidth
                            editedFieldsName="editedFields"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <ComputedTextFieldForm
                            control={control}
                            name={`enfants.rows.${index}.montantCapitalise`}
                            watchFields={[
                              `enfants.rows.${index}.partPerteAnnuelle`,
                              `enfants.rows.${index}.coefficientCapitalisation`,
                            ]}
                            compute={([
                              prejudiceEnfantAvantCapitalisation,
                              coefficientCapitalisation,
                            ]) =>
                              (prejudiceEnfantAvantCapitalisation || 0) *
                              (coefficientCapitalisation || 0)
                            }
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 2 },
                                suffix: '€',
                              } as IMaskNumberProps,
                            }}
                            editedFieldsName="editedFields"
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  />
                );
              }
            })}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <ComputedReadFieldForm
        control={control}
        name="enfants.total"
        watchFields={['enfants.rows']}
        compute={([rows]) => {
          return CalculsGlobal.sum(
            rows.map((prejudiceEnfant) => prejudiceEnfant.total),
          );
        }}
      />
      <ComputedReadFieldForm
        control={control}
        name="enfants.totalCapitauxDeces"
        watchFields={['enfants.rows']}
        compute={([rows]) =>
          CalculsGlobal.sum(
            rows.map((prejudiceEnfant) => prejudiceEnfant.capitauxDeces.total),
          )
        }
      />
      <ComputedReadFieldForm
        control={control}
        name="enfants.totalApresCapitauxDeces"
        watchFields={['enfants.rows']}
        compute={([rows]) =>
          CalculsGlobal.sum(
            rows.map(
              (prejudiceEnfant) => prejudiceEnfant.totalApresCapitauxDeces,
            ),
          )
        }
      />
      <Typography variant="h6" marginTop={4}>
        {t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.total.title',
        )}
      </Typography>
      <TableContainer>
        <NormalTable>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.nom.columnHeader',
                )}
              </TableCell>
              <TableCell align="left">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.rows.total.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.rows.capitauxDeces.columnHeader',
                )}
              </TableCell>
              <TableCell align="left">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.rows.totalApresCapitauxDeces.columnHeader',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              const victimeIndirecte = victimesIndirectes.find(
                (victimeIndirecte) =>
                  victimeIndirecte._id === field.victimeIndirecteId,
              );
              if (victimeIndirecte) {
                return (
                  <TableRow key={field.id}>
                    <TableCell align="left">
                      {`${victimeIndirecte.prenom} ${victimeIndirecte.nom}`}
                    </TableCell>
                    <TableCell align="center">
                      <ComputedReadFieldForm
                        control={control}
                        name={`enfants.rows.${index}.total`}
                        watchFields={[
                          `enfants.rows.${index}.prejudiceEntreDecesEtLiquidation`,
                          `enfants.rows.${index}.montantCapitalise`,
                        ]}
                        compute={([
                          prejudiceEntreDecesEtLiquidation,
                          montantCapitalise,
                        ]) =>
                          CalculPerteRevenusProche.getTotalEnfant({
                            prejudiceEntreDecesEtLiquidation,
                            montantCapitalise,
                          })
                        }
                        render={(value) => <>{fCurrency(value)}</>}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Accordion>
                        <AccordionSummary
                          sx={{
                            '& .MuiAccordionSummary-content': {
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'end',
                            },
                          }}
                        >
                          <ReadFieldForm
                            control={control}
                            name={`enfants.rows.${index}.capitauxDeces.total`}
                            render={(value) => <>{fCurrency(value)}</>}
                          />
                          <KeyboardArrowDown />
                        </AccordionSummary>
                        <LayoutTable>
                          <TableBody>
                            {procedure.tiersPayeurs.map(
                              (tiersPayeur, tiersPayeurIndex) => (
                                <TableRow key={tiersPayeurIndex}>
                                  <TableCell>{tiersPayeur}</TableCell>
                                  <TableCell align="right">
                                    <HiddenFieldForm
                                      control={control}
                                      name={`enfants.rows.${index}.capitauxDeces.tiersPayeurs.${tiersPayeurIndex}.tiersPayeur`}
                                      value={tiersPayeur}
                                    />
                                    <TextFieldForm
                                      control={control}
                                      name={`enfants.rows.${index}.capitauxDeces.tiersPayeurs.${tiersPayeurIndex}.montant`}
                                      InputProps={{
                                        inputComponent: MaskNumber as any,
                                        inputProps: {
                                          numberMask: { scale: 2 },
                                          suffix: '€',
                                        } as IMaskNumberProps,
                                      }}
                                      fullWidth
                                    />
                                  </TableCell>
                                </TableRow>
                              ),
                            )}
                            <ComputedReadFieldForm
                              control={control}
                              watchFields={[
                                `enfants.rows.${index}.capitauxDeces.tiersPayeurs`,
                              ]}
                              name={`enfants.rows.${index}.capitauxDeces.total`}
                              InputProps={{
                                inputComponent: MaskNumber as any,
                                inputProps: {
                                  numberMask: { scale: 2 },
                                } as IMaskNumberProps,
                              }}
                              compute={([tiersPayeurs]) => {
                                return CalculsGlobal.sum(
                                  tiersPayeurs.map(({ montant }) => montant),
                                );
                              }}
                              fullWidth
                            />
                          </TableBody>
                        </LayoutTable>
                      </Accordion>
                    </TableCell>
                    <TableCell align="center">
                      <ComputedReadFieldForm
                        control={control}
                        name={`enfants.rows.${index}.totalApresCapitauxDeces`}
                        watchFields={[
                          `enfants.rows.${index}.total`,
                          `enfants.rows.${index}.capitauxDeces.total`,
                        ]}
                        compute={([prejudiceEnfantTotal, capitauxDecesTotal]) =>
                          (prejudiceEnfantTotal || 0) -
                          (capitauxDecesTotal || 0)
                        }
                        render={(value) => <>{fCurrency(value)}</>}
                      />
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <ComputedPropsForm
        control={control}
        watchFields={['enfants.rows']}
        compute={([rows]) => {
          const totalEchus = CalculsGlobal.sum(
            rows.map((row) => row.prejudiceEntreDecesEtLiquidation),
          );
          const totalAEchoir = CalculsGlobal.sum(
            rows.map((row) => row.montantCapitalise || 0),
          );
          return {
            props: {
              echus: totalEchus,
              aEchoir: totalAEchoir,
              total: (totalEchus || 0) + (totalAEchoir || 0),
            },
          };
        }}
        render={({ echus, aEchoir, total }) => (
          <PartialRecapitulatif
            title={t(
              'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.partial.enfants.title',
            )}
            echus={echus}
            aEchoir={aEchoir}
            total={total}
          />
        )}
      />
    </Stack>
  );
};
